@charset 'utf-8';

@import "utils/functions";
@import "utils/mixins";
@import "utils/flexbox";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "components/buttons";
@import "components/form";
@import "components/pagination";
@import "components/modal";
@import "components/message";
@import "components/breadcrumb";
@import "components/slide-all";
@import "components/loader";

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/navigation";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home_1_hero";
@import "pages/home_2_presentation";
@import "pages/home_3_projet";
@import "pages/home_4_activite";
@import "pages/home_5_blog";
@import "pages/home_6_contact";
@import "pages/blog";
@import "pages/page2";
@import "pages/page3";
@import "pages/page4";
@import "pages/page5";
@import "pages/page6";
@import "pages/page7";
@import "pages/page8";
@import "pages/contact";

@import "espace-client/espace-client";
@import "espace-client/login";
@import "espace-client/actualite";
@import "espace-client/navigation";

@import "vendors/swiper-bundle.scss";

@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poppins:wght@300;400;500;600;700;800&display=swap");

// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";
