.page-home .article {
  display: none !important;
  // max-width: 1000px;
  // margin: 4rem auto;
  padding: 5rem 0;
  background-color: var(--gray-2);

  .blog__title {
    text-align: center;
    margin-bottom: calc(var(--gap) * 8);
  }
}
.page-home .blog-featured {
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-auto-flow: dense;
  grid-gap: 25px;
  justify-content: center;
  //   ---------------------- item
  .items-row {
    position: relative;
    // @include shadow-1;
    min-height: 450px;
    // border-radius: 12px;
    overflow: hidden;
    padding-bottom: 1rem;
    background-color: white;
  }
  //   ---------------------- icon modifier
  .icons {
    position: absolute;
    top: 0;
    left: 0;
  }
  //   ---------------------- image
  .item-image {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  //   ---------------------- image
  .item-title {
    font-size: rem(18);
    padding: 0 1rem;
    color: var(--color-1);
    font-weight: 500;
    margin: 0;
  }
  //   ---------------------- texte
  p {
    padding: 7px 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@include breakpoint(1080) {
  .page-home .blog-featured {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  .page-home .blog-featured .item-image {
    height: 300px;
  }
}

@include breakpoint(550) {
  .page-home .blog-featured .item-image {
    height: 200px;
  }
}
