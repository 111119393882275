%form {
  display: block;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100% !important;

  //----------------------------- STYLE
  font-family: var(--font-normal);
  background-color: white;
  box-shadow: none;
  color: var(--color-dark);
  border: 1px solid var(--gray-4);
  border-radius: 4px;
  font-size: rem(16);
  // padding: 0 rem(50) 0 rem(16);
  padding-left: 15px;
  transition: box-shadow 200ms ease;

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.05);
    border: 1px solid var(--color-1);
  }

  &:focus {
    border: 1px solid var(--color-1);
    background-color: rgba($color: var(--color-1), $alpha: 0.05);
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
  font-size: 1.2rem;
  color: var(--color-1);
}

input:-webkit-autofill::first-line {
  font-size: rem(20);
  color: var(--color-1);
}

// ------------------------------------------------ checkbox hikashop
.hikashop_filter_checkbox {
  display: inline-flex;
  align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  background-color: var(--gray-4);
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  padding: 0 !important;
  box-shadow: none;
  width: 25px !important;
  height: 25px !important;
  border-radius: 2px;
  cursor: pointer;

  &:checked {
    &::after {
      content: "✔";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 25px;
      text-align: center;
      width: 100%;
      font-size: 1.1rem;
      background-color: var(--color-1);
      color: white;
    }
  }
  &:hover {
    border: 0;
  }
  &:focus {
    border: 0;
    background-color: white;
  }
}

.form-group,
.control-group {
  position: relative;
  margin-bottom: 1rem;
}

.fox-column {
  min-height: initial !important;
}

fieldset {
  border: 0;
}

legend {
  font-size: rem(22);
  margin-bottom: 1rem;
}

.btn--submit {
  width: 100%;
}

label {
  position: relative;
  display: block;
  margin-left: rem(10);
  text-transform: uppercase;
  // pointer-events: none;
  font-size: rem(15);
  // color: $gray-8;
  font-weight: 300;
}

input {
  @extend %form;
  height: rem(50) !important;
}
// --------------------------- SELECT - supprimer arrow sur ie
select::-ms-expand {
  display: none;
}
select {
  @extend %form;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem(60);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMXJlbSIgd2lkdGg9IjFyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTEuNWExIDEgMCAwMS0uNzEtLjI5bC01LTVhMSAxIDAgMDExLjQyLTEuNDJMOCA5LjA5bDQuMjktNC4zYTEgMSAwIDExMS40MiAxLjQybC01IDVhMSAxIDAgMDEtLjcxLjI5eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1.3rem;
  padding-right: 1.8rem;
  background-position: 95% center;
}

textarea {
  @extend %form;
  padding-top: 10px;
  max-width: 100%;
  max-height: 250px;
  line-height: 1.5 !important;
  resize: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-1);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-1);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-1);
}

@include breakpoint(550) {
  %form {
    font-size: rem(16) !important;
  }
}
