.header {
  position: fixed;
  z-index: 1001;
  width: 100%;
  top: 0;
  padding: 0 1rem;
  left: 0;
  transition: all 0.25s;
  color: white;
  height: 100px;
}

.header--fixed {
  background-color: white;
  box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  color: initial;
}

.header--hidden {
  transform: translateY(-130px);
}

.header__container {
  display: grid;
  grid-template-columns: 200px 1fr 250px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  // height: 100px;
}

// ------------------------ Logo
.header__logo {
  display: flex;
  justify-content: flex-start;
  height: 100px;

  img {
    position: absolute;
    top: 0;
    max-height: 150px;
  }
}

.header--fixed .header__logo img {
  max-height: 100px;
}

// ------------------------ Sociaux

.fa-facebook-square{
  font-size:1.5rem;
  color:white;
  margin-left:7px;
}

.header__social {
  display: flex;
  align-items: flex-end;
  // color: white;

  .social__title {
    display: block;
    text-transform: uppercase;
    font-size: rem(14);
    line-height: 1;
  }
}

.header__phone {
  margin-right: 2rem;
  font-size: rem(22);
  line-height: 30px;

  .phone__number {
    font-weight: 500;
    font-size: rem(26);
    // height: 30px;
  }
}

.header__reseaux {
  display: none;
  i {
    font-size: rem(30);
    margin-left: rem(5);
  }
}

@include breakpoint(1280) {
  .header__container {
    grid-template-columns: minmax(100px, 1fr) minmax(400px, 1fr) minmax(
        50px,
        1fr
      );
  }
  .header__menu {
    order: 3;
    text-align: right;
  }
  .header__reseaux {
    display: none;
  }
}

@include breakpoint(650) {
  .header {
    padding: 0 1rem;
  }
  .header__container {
    grid-template-columns: minmax(100px, 1fr) minmax(120px, 1fr) minmax(
        100px,
        1fr
      );
  }
  .header__logo img {
    max-height: 100px;
  }
  .header__social {
    justify-content: center;
    text-align: center;
  }
  .header__social .social__title {
    margin-bottom: 0;
  }
  .header__phone {
    margin-right: 0;
  }
  .header__phone .phone__number {
    font-size: rem(16);
  }
  .header__reseaux {
    display: none;
  }
  .header__menu {
    text-align: center;
  }
}

@include breakpoint(440) {
  .header__container {
    grid-template-columns: minmax(100px, 1fr) minmax(120px, 1fr) minmax(
        80px,
        1fr
      );
    gap: 10px;
  }
}
