#preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}

.page-101 #preloader {
  display: block;
}

#status {
  width: 250px;
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: salmon;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: -125px 0 0 -125px;
}
