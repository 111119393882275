// -------------------------------------- ALL

.espace-client {
  .main {
    margin: 3rem auto 7rem auto;
    max-width: 980px;
  }
  .main__center {
    display: grid;
    grid-template-columns: 220px 1fr;
    margin: 1rem 0;
    gap: 20px;
  }

  aside {
    border-right: 2px solid var(--gray-3);
  }

  #adminForm {
    margin: 0;
  }
}

// ------------------------------ Popover

.popover,
.tooltip {
  display: none !important;
}

// ------------------------------ Btn
.btn.dropdown-toggle {
  display: none;
}

.btn.btn-default,
.btn.jmodedit {
  background-color: var(--color-1) !important;
  border: 0 !important;
  text-shadow: none !important;
  background-image: none;
  font-size: rem(16);
  i {
    display: none;
  }
  .icon-edit::before {
    color: white !important;
    font-size: rem(20) !important;
  }
  &:hover {
    color: white !important;
  }
}

// ------------------------------ main top

.espace-client {
  .main__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid var(--gray-3);
    padding-bottom: 1rem;

    .espace-client__title {
      font-size: rem(32);
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

// -------------------------------------- ACCUEIL

.espace-client__dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  gap: 20px;

  .card {
    border: 1px solid var(--gray-3);
    // height: 220px;
    a {
      display: block;
      padding: 2rem 1rem;
    }
    i {
      display: block;
      margin: auto;
      text-align: center;
      font-size: 3rem;
      color: var(--color-1);
    }
    img {
      display: block;
      max-width: 70px;
      max-height: 70px;
      margin: auto;
    }

    .card__title {
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin: 1rem 0;
      font-weight: 500;
    }

    p {
      text-align: center;
      font-size: rem(16);
    }
  }
}

@include breakpoint(780) {
  .espace-client .main__center {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(550) {
  .espace-client .main__top {
    display: block;
    text-align: center;
  }
  .espace-client .main__top .espace-client__title {
    font-size: 1.5rem;
  }
}
