html {
  // scroll-behavior: smooth; / Remplacé par le smooth JS compatible IOS
  // height: 4000px;
}

body {
  word-break: break-word;
  background-color: var(--color-light);
  text-rendering: optimizeLegibility;
}

#wrapper {
  position: relative;
  // width: 100%;
  margin: auto;
  min-height: 100vh;
  // overflow: hidden;
}

.main {
  --full-width: 16px;
  // padding: 0 var(--full-width);
}

.full-width {
  width: calc(100% + var(--full-width) * 2);
  margin-left: calc(-1 * var(--full-width));
}

.container {
  max-width: rem(1180);
  margin: auto;
  padding: 0 1rem;
}

@include breakpoint(1440) {
  .main {
    --full-width: 14px;
  }
}

@include breakpoint(980) {
  .main {
    --full-width: 12px;
  }
}

@include breakpoint(550) {
  .main {
    --full-width: 8px;
  }
}
