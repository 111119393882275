html {
  font-size: $font-size-base;
}

body {
  font-family: var(--font-normal);
  line-height: 1.5;
  color: var(--color-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

/*
************* TITRE
* ===================================
*/

.title__h1,
h1 {
  font-family: var(--font-titre);
  line-height: 1.5;
  font-size: var(--h1);
}
h2 {
  font-family: var(--font-titre);
  font-size: var(--h2);
  line-height: 1.3;
  margin-bottom: calc(var(--gap) * 8);
}
// ------------ EO H2
h3 {
  font-family: var(--font-titre);
  font-weight: normal;
  font-size: var(--h3);
  line-height: 1.5;
}

h4 {
  font-size: var(--h4);
  line-height: 1.5;
}

h5 {
  font-size: var(--h5);
  line-height: 1.5;
}

h6 {
  font-size: var(--h6);
  line-height: 1.5;
}
/*
************* TEXTE
* ===================================
*/

p {
  text-align: justify;
  font-size: var(--p);
  line-height: 1.7;
  font-weight: 300;
  margin-bottom: calc(var(--gap) * 2);
}

li.list {
  font-size: var(--p);
  line-height: 1.7;
  font-weight: 500;
}

.intro {
  font-weight: 500;
  color: var(--color-1);
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    //color: var(--color-1);
    //transition: color 200ms ease-in-out;
  }
}

address {
  font-style: normal;
}
