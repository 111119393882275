::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-1);
}

::-webkit-scrollbar-thumb {
  background: var(--color-2);
  border: 2px solid var(--color-1);
  border-radius: 7px;
}
