// ------------------------------------ MODELE 2

.home-activite {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  &.home-activite--1 .right,
  &.home-activite--2 .left,
  &.home-activite--3 .right,
  &.home-activite--4 .left,
  &.home-activite--5 .right {
    padding: 3rem 5rem;
  }

  &.home-activite--1 .left,
  &.home-activite--2 .right,
  &.home-activite--3 .left,
  &.home-activite--4 .right,
  &.home-activite--5 .left {
    height: 100%;
  }

  h2 {
    margin-bottom: calc(var(--gap) * 4);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  li {
    @include inline-flex;
    align-items: center;
    margin-bottom: var(--gap);
    i {
      margin-right: 8px;
      color: var(--color-1);
      font-size: 1.6rem;
    }
  }

  .btn {
    margin-top: 2rem;
  }

  .activite__avantages {
    @include flexbox;
    gap: 4vw;
    margin-top: 2rem;

    .avantages__nb {
      display: block;
      font-weight: 700;
      line-height: 1;
      font-size: rem(38);
      color: var(--color-1);
    }

    .avantages__title {
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .home-activite .activite__avantages {
    gap: 2vw;
  }
  .home-activite .activite__avantages .avantages__nb {
    font-size: 2rem;
  }
  .home-activite .activite__avantages .avantages__title {
    font-size: 0.8rem;
  }
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .home-activite.home-activite--1 .right,
  .home-activite.home-activite--2 .left,
  .home-activite.home-activite--3 .right,
  .home-activite.home-activite--4 .left,
  .home-activite.home-activite--5 .right {
    padding: 2rem 3rem;
  }
}

@include breakpoint(1380) {
  .home-activite .activite__avantages .avantages__nb {
    font-size: 1.8rem;
  }
}

@include breakpoint(1180) {
  .home-activite {
    grid-template-columns: 1fr;
  }
  .home-activite.home-activite--1 .right,
  .home-activite.home-activite--2 .left,
  .home-activite.home-activite--3 .right,
  .home-activite.home-activite--4 .left,
  .home-activite.home-activite--5 .right {
    margin: var(--section) 0;
  }
  .home-activite.home-activite--1 .left,
  .home-activite.home-activite--4 .right {
    display: none;
  }
  .home-activite.home-activite--2 .left {
    order: 2;
  }
  .home-activite .activite__avantages .avantages__nb {
    font-size: 3rem;
  }
  .home-activite .activite__avantages .avantages__title {
    font-size: 1.1rem;
  }
}

@include breakpoint(980) {
  .home-activite .activite__avantages .avantages__nb {
    font-size: 1.5rem;
  }
  .home-activite .activite__avantages .avantages__title {
    font-size: 1.1rem;
  }
}

@include breakpoint(780) {
}

@include breakpoint(550) {
  .home-activite.home-activite--1 .right,
  .home-activite.home-activite--2 .left,
  .home-activite.home-activite--3 .right,
  .home-activite.home-activite--4 .left,
  .home-activite.home-activite--5 .right {
    padding: 0 1rem;
  }
  .home-activite .activite__avantages {
    display: block;
    text-align: center;
    div {
      margin: 1rem 0;
    }
  }
  .home-activite .activite__avantages .avantages__nb {
    font-size: 3rem;
  }
  .home-activite .activite__avantages .avantages__title {
    font-size: 1.1rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
