.modal {
  position: fixed;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: var(--color-dark), $alpha: 0.55);
}

.modal__wrapper {
  position: absolute;
  max-width: 1000px;
  // max-height: 50vh;
  // overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: var(--color-light);
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 20px;
  @include shadow-2;
  transition: all 300ms;

  .form-group {
    margin-bottom: 1rem;
  }
}

.modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: rem(11);
  font-weight: 500;
  svg {
    margin-left: rem(6);
  }
}

//------------------------------------- MODAL ACTIVE
.body__modal--active {
  height: 100vh;
  overflow: hidden;
}

.modal--active {
  opacity: 1;
  z-index: 10000;
  transition: all 200ms;

  .modal__wrapper {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@include breakpoint(800) {
  .modal__wrapper {
    width: 95%;
    max-height: 90vh;
    padding: 3rem 1rem 2rem 1rem;
  }
}
