.page-115,
.page-116 {
  a {
    color: var(--color-dark) !important;
  }
}

.header--fixed .menu__main a {
  color: var(--color-dark) !important;
}

.menu__main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5rem;
  //------------------------------------------------------ li
  li {
    margin: 0 1rem;
  }

  .item-101 {
    display: none !important;
  }

  //------------------------------------------------------ a
  a {
    color: white;
    font-size: rem(20);
    font-weight: 500;
    text-transform: uppercase;
    // transition: all 200ms;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 3px;
      background-color: var(--color-1);
      transition: all 0.15s;
    }
  }

  li.active a,
  a.active {
    color: var(--color-1);
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: var(--color-1);
    }
  }
  //------------------------------------------------------ hover
  a:hover {
    color: var(--color-1);
    &::after {
      width: 100%;
    }
  }

  //------------------------------------------------------ sous-menu

  .nav-child {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 2rem;
    left: -2rem;
    z-index: -1;
    width: 285px;
    background-color: white;
    box-shadow: 3px 6px 9px rgba($color: #000000, $alpha: 0.16);
    border-radius: 15px;
    padding: 0.5rem 2rem;
    transition: all 0.15s;

    li {
      margin-left: 0;
      padding: 12px 0;
      border-bottom: 1px solid var(--color-3);
    }

    li:last-of-type {
      border: 0;
    }

    a {
      text-transform: capitalize;
    }
  }

  .parent {
  }

  .parent:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: -2rem;
      display: block;
      height: 2rem;
      width: 100%;
    }
    .nav-child {
      z-index: 100;
      opacity: 1;
      visibility: visible;
    }
  }
}

//------------------------------------------------------ bouton menu
#menu__btn {
  display: none;
  position: relative;
  cursor: pointer;
  z-index: 9999;
  outline: 0;
  background-color: transparent;
  border: 0;
  color: white;
  .menu__mobile__title {
    display: inline-flex;
    text-transform: uppercase;
    // color: var(--color-1);
    // color: white;
    line-height: 0;
    font-family: var(--font-normal);
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 40px;
  height: 3px;
  //   background-color: var(--color-1);
  background-color: white;
  margin-bottom: 7px;
  border-radius: 2px;
}

.header--fixed #menu__btn {
  color: initial;
}
.header--fixed .menu__bar {
  background-color: var(--color-dark);
}

#menu__btn.menu__btn--active {
  position: absolute;
  top: 8px;
  right: 8px;
  .menu__bar {
    transition: all 150ms;
    background-color: var(--color-1);
  }
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(1600) {
  .menu__main {
    margin-right: 0;
    a {
      font-size: 1rem;
    }
  }
}

@include breakpoint(1280) {
  #menu__btn {
    display: inline-block;
  }
  .menu__main {
    position: fixed;
    top: 0;
    right: -275px;
    padding: 1rem 0 5rem 0;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: stretch;
    width: 275px;
    height: 100vh;
    overflow-y: auto;
    background-color: white;

    transition: all 200ms;
    z-index: -1;

    a {
      color: initial;
    }
    // -------------------------- ul
    ul {
      display: flex;
      flex-direction: column;
    }

    a:hover::after {
      width: 0;
    }

    li.active a,
    a.active {
      color: var(--color-1);
      &::after {
        content: none;
      }
    }

    ul.nav-child {
      position: relative;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      top: 0;
      left: 0;
      width: auto;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0;
      transition: none;
      counter-reset: nav;

      li {
        margin-left: 0;
        // padding: 12px 0;
        border-bottom: 1px solid var(--color-3);
        list-style-type: none;

        &::before {
          counter-increment: nav;
          content: counters(nav, " ") ".";
          font-size: rem(18);
          display: inline-flex;
          margin-right: rem(7);
        }
      }
    }

    // -------------------------- li
    li {
      padding: rem(10) 0;
      margin: 0 0 0 1.5rem;
    }
    .item-101 {
      display: block;
    }
  }
  // -------------------------- menu active
  .header__menu--active .menu__main {
    right: 0;
    z-index: 9998;
  }
}
