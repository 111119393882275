// ------------------------------------ MODELE 2

.home-projet {
  position: relative;
  padding: 9rem 0;
  color: white;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(234, 88, 13);
    background: linear-gradient(
      180deg,
      rgba(234, 88, 13, 0.35) 0%,
      rgba(234, 88, 13, 0.8) 100%
    );
  }
  .projet__title,
  p {
    position: relative;
    z-index: 10;
  }

  .projet__title {
    font-size: rem(55);
    font-family: var(--font-titre);
  }

  p {
    font-size: rem(35);
    font-weight: bold;
    font-family: var(--font-titre);
  }

  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .btn {
    margin-top: 2rem;
    color: white;
    border-color: white;
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  .home-projet .projet__title {
    font-size: 3rem;
  }
}

@include breakpoint(1440) {
  .home-projet .projet__title {
    font-size: 2rem;
  }
  .home-projet p {
    font-size: 1.7rem;
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
}

@include breakpoint(780) {
}

@include breakpoint(550) {
  .home-projet {
    padding: 4rem 0;
  }
  .home-projet .projet__title {
    font-size: 1.55rem;
  }
  .home-projet p {
    font-size: 1.5rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
