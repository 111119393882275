.espace-client__menu {
  li {
    margin-right: rem(10);
  }
  a {
    display: block;
    font-size: rem(16);
    text-transform: uppercase;
    padding: 10px 0 10px 15px;
    font-weight: 500;
  }
  img {
    display: none;
  }

  .active > a {
    color: white;
    background-color: var(--color-1);
  }
}
